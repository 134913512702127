import './EconsentSurvey.less'

import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect, useReducer, useState } from 'react'

import SurveyUnavailable from '../../../../../assets/images/survey-unavailable.svg'
import { useScopedIntl } from '../../../../../hooks'
import {
  AccountType,
  BackendError,
  EconsentStatus,
  Feature,
  FileAnswer,
  QuestionType,
  SaveDeleteAnswerHandlers,
  SurveyAnswer,
  UiSettings,
  checkEconsentSurvey,
  createEsignatureLink,
  deleteEconsentFileAnswer,
  deleteEconsentSurveyAnswer,
  fetchEconsentConditionalLogic as fetchEconsentConditionalLogicRequest,
  fetchEconsentFilesUrls,
  fetchEconsentSurvey,
  fetchStudyTranslatedLanguages,
  fetchUiSettings as fetchUiSettingsRequest,
  saveEconsentFileAnswer,
  saveEconsentSurveyAnswer,
  signEconsentSurvey
} from '../../../../../requests'
import { routes } from '../../../../../routes'
import { getParsedHash, isAnswerValueEmpty, localeFromPath } from '../../../../../utils'
import { DatacLoading, DatacMessage } from '../../../../common'
import {
  EndPage,
  Survey,
  SurveyContextConsumer,
  SurveyContextProvider,
  SurveyDataTypes,
  SurveyDetailsInfoItem,
  SurveySaveAnswerFn,
  preservedData,
  surveyDataInitialState,
  surveyDataReducer
} from '../../../../shared/Survey'
import { EconsentPdf } from './EconsentPdf'
import { EconsentRejectModal } from './EconsentRejectModal'
import { EconsentSign } from './EconsentSign'
import { EconsentThankYou } from './EconsentThankYou'

const ExpiredEconsentPage: React.FC = () => {
  const econsentSurveyIntl = useScopedIntl('survey.expired')
  return (
    <div className="econsent-survey-expired">
      <SurveyUnavailable />
      <p>{econsentSurveyIntl('label')}</p>
      <p>{econsentSurveyIntl('content.1')}</p>
      <p>{econsentSurveyIntl('content.2')}</p>
    </div>
  )
}

export const EconsentSurvey: React.FC = () => {
  const intl = useScopedIntl('')
  const surveyInt = useScopedIntl('survey')
  const econsentSurveyIntl = useScopedIntl('studies.econsent.survey')
  const [token, qrCode] = getParsedHash()
  const [isCheckingToken, setIsCheckingToken] = useState(true)
  const [loadingSurvey, setLoadingSurvey] = useState(false)
  const [isExpired, setIsExpired] = useState(false)
  const [surveyData, surveyDataDispatch] = useReducer(surveyDataReducer, surveyDataInitialState)
  const [pdfNextDisabled, setPdfNextDisabled] = useState(true)
  const [signNextDisabled, setSignNextDisabled] = useState(true)
  const [isSigned, setIsSigned] = useState(false)
  const [isRejected, setIsRejected] = useState(false)
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
  const [pdfFile, setPdfFile] = useState<FileAnswer>(null)
  const [handSignature, setHandSignature] = useState('')
  const [initialStep, setInitialStep] = useState<number>(null)
  const [showSignatureError, setShowSignatureError] = useState(false)
  const [uiSettings, setUiSettings] = useState<UiSettings>(null)
  const [isFetchingRedirectLink, setIsFetchingRedirectLink] = useState(false)
  const currentLanguage = localeFromPath()

  useEffect(() => {
    if (!token) {
      navigate(routes.notFound(AccountType.Subject))
      return
    }

    const currentPreservedData = preservedData()
    if (currentPreservedData) {
      setInitialStep(parseInt(currentPreservedData?.initialStep, 10))
    }

    setIsCheckingToken(true)
    setLoadingSurvey(true)

    checkEconsentSurvey(
      { token, qrCode },
      {
        onSuccess: () => {
          setIsCheckingToken(false)
          fetchUiSettings()
        },
        onNotFound: () => {
          navigate(routes.notFound(AccountType.Subject))
        },
        onExpired: () => {
          setIsExpired(true)
          setIsCheckingToken(false)
        },
        onRequestError: () => {
          DatacMessage.error(econsentSurveyIntl('unknown_error.title'), econsentSurveyIntl('unknown_error.content'))
          setIsCheckingToken(false)
        }
      }
    )
  }, [token])

  const fetchSurvey = () => {
    fetchEconsentSurvey(
      { token, qrCode },
      {
        onSuccess: surveyData => {
          surveyDataDispatch({ type: SurveyDataTypes.SET, survey: surveyData })
          if (
            surveyData.status === EconsentStatus.NeedsCounterSignature ||
            surveyData.status === EconsentStatus.Consented
          )
            setIsSigned(true)
          if (surveyData.status === EconsentStatus.Rejected) setIsRejected(true)
          if (surveyData.status === EconsentStatus.Archived) setIsExpired(true)
          fetchEconsentConditionalLogic(token)
        },
        onNotFound: () => {
          navigate(routes.notFound(AccountType.Subject))
        },
        onExpired: () => {
          setIsExpired(true)
          setLoadingSurvey(false)
        },
        onWrongCode: () => {
          DatacMessage.error(
            econsentSurveyIntl('wrong_code_error.title'),
            econsentSurveyIntl('wrong_code_error.content')
          )
          setLoadingSurvey(false)
        },
        onRequestError: () => {
          DatacMessage.error(econsentSurveyIntl('unknown_error.title'), econsentSurveyIntl('unknown_error.content'))
          setLoadingSurvey(false)
        }
      }
    )
  }

  const fetchEconsentConditionalLogic = (token: string) => {
    fetchEconsentConditionalLogicRequest(
      { token, qrCode },
      {
        onSuccess: conditionalRules => {
          surveyDataDispatch({ type: SurveyDataTypes.SET_CONDITIONAL_RULES, conditionalRules })
          setLoadingSurvey(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const fetchUiSettings = () => {
    return fetchUiSettingsRequest(
      { token },
      {
        onSuccess: uiSettings => {
          setUiSettings(uiSettings)
          if (uiSettings.esignatureEnabled) setSignNextDisabled(false)
          fetchTranslatedLanguages()
          fetchSurvey()
        },
        onNotFound: () => {
          navigate(routes.notFound(AccountType.Subject))
        },
        onRequestError: () => {
          DatacMessage.error(econsentSurveyIntl('unknown_error.title'), econsentSurveyIntl('unknown_error.content'))
        }
      }
    )
  }

  const fetchTranslatedLanguages = () => {
    return fetchStudyTranslatedLanguages(
      { token, feature: Feature.Econsent, qrCode },
      {
        onSuccess: languages => setUiSettings(uiSettings => ({ ...uiSettings, languages })),
        onRequestError: () => DatacMessage.error(econsentSurveyIntl('unknown_error.title'))
      }
    )
  }

  const saveAnswer: SurveySaveAnswerFn = (answer, question) => {
    return new Promise((resolve, reject) => {
      const handlers = {
        onSuccess: (response?: SurveyAnswer) => {
          if (response) {
            surveyDataDispatch({ type: SurveyDataTypes.UPDATE_ANSWER, answer: response })
            if (response.validationMessages.length) reject()
          }
          resolve()
        },
        onDeleteSuccess: () => {
          const answerId = surveyData.answers.find((a: SurveyAnswer) => a.questionId === question.id)?.id
          surveyDataDispatch({
            type: SurveyDataTypes.REMOVE_ANSWER,
            id: answerId
          })
          resolve()
        },
        onRequestError: (code: number) => {
          const questionPrefix =
            code && code === BackendError.EPRO_STATUS_PUBLISHED ? 'no_question_error' : 'save_error'

          DatacMessage.error(surveyInt(`answer.${questionPrefix}.title`), surveyInt(`answer.${questionPrefix}.content`))
          reject()
        }
      }

      const uploadFileHandlers = {
        onFileSizeExceeded: () => {
          DatacMessage.warning(intl('file_upload.error.title'), intl('api.9002'))
          reject()
        },
        onWrongFileFormat: () => {
          DatacMessage.warning(intl('file_upload.error.title'), intl('api.9007'))
          reject()
        }
      }

      const commonParams = {
        questionId: question.id,
        recordId: surveyData.recordId,
        language: currentLanguage,
        token,
        qrCode
      }

      const deleteFn = (handlers: SaveDeleteAnswerHandlers) => {
        if (question.type === QuestionType.File) {
          const currentAnswer = surveyData.answers.find(a => a.questionId === question.id)
          deleteEconsentFileAnswer({ fileId: currentAnswer.value[0].id }, handlers, {
            token,
            qrCode,
            recordId: surveyData.recordId
          })
        } else {
          deleteEconsentSurveyAnswer(commonParams, handlers)
        }
      }

      const saveFn = (handlers: SaveDeleteAnswerHandlers) =>
        question.type === QuestionType.File
          ? saveEconsentFileAnswer(
              { ...commonParams, fileId: (answer as FileAnswer[])?.[0].id },
              { ...handlers, ...uploadFileHandlers }
            )
          : saveEconsentSurveyAnswer({ ...commonParams, answer }, handlers)

      // File only: if we need to replace a file, first delete and then send new one
      if (question.type === QuestionType.File && surveyData.answers.find(a => a.questionId === question.id)?.value) {
        deleteFn({
          onSuccess: () =>
            isAnswerValueEmpty(question.type, answer)
              ? handlers.onDeleteSuccess()
              : saveFn({ onSuccess: handlers.onSuccess, onRequestError: handlers.onRequestError }),
          onRequestError: handlers.onRequestError
        })
        return
      }

      if (isAnswerValueEmpty(question.type, answer)) {
        deleteFn({ onSuccess: handlers.onDeleteSuccess, onRequestError: handlers.onRequestError })
      } else {
        saveFn({ onSuccess: handlers.onSuccess, onRequestError: handlers.onRequestError })
      }
    })
  }

  const onSignEconsent = () => {
    return new Promise<void>((resolve, reject) => {
      if (uiSettings.esignatureEnabled) {
        setSignNextDisabled(true)
        setIsFetchingRedirectLink(true)
        createEsignatureLink(
          { token, qrCode },
          {
            onSuccess: link => {
              window.location.href = link
            },
            onRequestError: code => {
              DatacMessage.genericError(intl, code)
              setSignNextDisabled(false)
              reject()
            }
          }
        )
        return
      }

      if (!handSignature) {
        setShowSignatureError(true)
        return
      }

      signEconsentSurvey(
        { token, signature: handSignature },
        {
          onSuccess: pdfFile => {
            setPdfFile(pdfFile)
            setIsSigned(true)
            resolve()
          },
          onRequestError: () => {
            DatacMessage.error(econsentSurveyIntl('sign.error.title'), econsentSurveyIntl('sign.error.content'))
            reject()
          }
        }
      )
    })
  }
  const endPages = () => {
    const pages: EndPage[] = []

    if (!isSigned && !isRejected) {
      if (!uiSettings?.esignatureEnabled && !isSigned && !isRejected) {
        pages.push({
          component: <EconsentPdf token={token} setNextDisabled={setPdfNextDisabled} />,
          nextDisabled: pdfNextDisabled
        })
      }

      pages.push({
        component: (
          <EconsentSign
            setNextDisabled={setSignNextDisabled}
            nextDisabled={signNextDisabled}
            setHandSignature={setHandSignature}
            setShowSignatureError={setShowSignatureError}
            showSignatureError={showSignatureError}
            esignatureEnabled={uiSettings?.esignatureEnabled}
          />
        ),
        nextName: uiSettings?.esignatureEnabled ? intl('common.sign') : econsentSurveyIntl('sign.button'),
        nextDisabled: signNextDisabled,
        nextFunction: onSignEconsent
      })
    }

    return pages.concat({
      component: <EconsentThankYou token={token} qrCode={qrCode} file={pdfFile} isRejected={isRejected} />,
      nextName: econsentSurveyIntl('back_to_home'),
      nextRedirectRoute: routes.subjectDashboard
    })
  }

  const detailsBoxes = () =>
    isRejected ? null : (
      <SurveyDetailsInfoItem
        icon="xCircle"
        title={econsentSurveyIntl('reject')}
        iconClassName="econsent-survey__reject-icon"
        onClick={() => setIsRejectModalOpen(true)}
        color={uiSettings?.mainColor}
      />
    )

  return (
    <div className="econsent-survey" key={token}>
      <DatacLoading isLoading={isCheckingToken}>
        {isExpired ? (
          <ExpiredEconsentPage />
        ) : (
          <SurveyContextProvider
            subjectId={surveyData?.subjectId}
            blocks={surveyData?.blocks}
            answers={surveyData?.answers}
            onFileUrlFetch={fetchEconsentFilesUrls(token, qrCode)}
            titleLabel={surveyInt('details.econsent')}
            uiSettings={uiSettings}
            token={token}
            showProgress
          >
            <SurveyContextConsumer>
              {() => (
                <DatacLoading isLoading={loadingSurvey || isFetchingRedirectLink}>
                  <Survey
                    name={surveyData?.surveyName}
                    endPages={endPages()}
                    onAnswerSave={saveAnswer}
                    detailsBoxes={detailsBoxes()}
                    hideQuestions={isRejected || isSigned}
                    initialStep={initialStep}
                  />
                </DatacLoading>
              )}
            </SurveyContextConsumer>
            {!isRejected && (
              <EconsentRejectModal
                isOpened={isRejectModalOpen}
                onClose={() => setIsRejectModalOpen(false)}
                onRejectionSent={() => setIsRejected(true)}
                token={token}
                qrCode={qrCode}
              />
            )}
          </SurveyContextProvider>
        )}
      </DatacLoading>
    </div>
  )
}
